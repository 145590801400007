import React, { useState } from 'react';
import { connect } from 'react-redux';
import { navigate } from '@reach/router';
import Button from '../../Button1';
import Column from '../../Column';
import Input from '../../ThemedInput';
import { login } from '../../../actions/userActions';
import {
  Container, Body, Title, SubHeading,
  ForgotPasswordLink,
} from './styled';
import background from './background_image.png';

function Login({ login }) {
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  return (
    <Container>
      <img src={background} />
      <Body>
        <Title>Hello There,</Title>
        <SubHeading>Your dream wedding gown is waiting for you. </SubHeading>
        <Column>
          <Input label="Email Address" value={email} update={setEmail} />
          <Input style={{ marginTop: 10 }} type="password" label="Password" value={password} update={setPassword} />
          <Button style={{
            width:300, height: 45, marginTop: 30,
          }} color="#7DBEC8" onClick={() => {
            if (email && password) {
              login({ email, password });
            } else {
              alert('Please enter a valid email an password');
            }
          }}>Sign In</Button>
          <Button style={{
            width:300, height: 45, marginTop: 10,
          }} color="#124C56" onClick={() => navigate('/create-account')}>Create Account</Button>
        </Column>
        <ForgotPasswordLink onClick={() => navigate('/login/reset')}>I forgot my password</ForgotPasswordLink>
      </Body>
    </Container>
  );
}

const mapDispatchToProps = { login };

export default connect(null, mapDispatchToProps)(Login);
