import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  > img {
    object-fit: cover;
    width: 100vw;
    height: 144vw;
    display: flex;
  }
`;

export const Body = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.div`
  font-family: SilverSouthSerif;
  font-size: 8vw;
  color: #000000;
  letter-spacing: 0;
  text-transform: uppercase;
`;

export const SubHeading = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 700;
  font-size: 4vw;
  color: #464855;
  letter-spacing: 0;
  line-height: 48px;
  margin-bottom: 3vw;
`;

export const InputContainer = styled.div`
  height: 12.37vw;
  border: 1px solid rgba(225, 225, 225, 0.74);
  width: 75.00vw;
  margin-bottom: 5.938vw;
  input {
    height: calc(100% - 3.125vw);
    color: #A7ABBE;
    font-size: 4.375vw;
    padding: 1.563vw;
    width: calc(100% - 3.125vw);
  }
`;

export const SignInButton = styled.div`
  height: 12.37vw;
  width: 75.00vw;
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 4.375vw;
  color: #FFFFFF;
  letter-spacing: 0.88px;
  text-transform: uppercase;
  background-color: #FE95A2;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5vw;
`;

export const CreateAccountButton = styled.div`
  height: 12.37vw;
  width: 75.00vw;
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 4.375vw;
  color: #FFFFFF;
  letter-spacing: 0.88px;
  text-transform: uppercase;
  background-color: #52639B;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2vw;
`;

export const ForgotPasswordLink = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 12px;
  color: #464855;
  letter-spacing: 0;
  text-align: center;
  text-decoration: underline;
`;