import styled from 'styled-components';

export const Container = styled.div`
> div {
  position: relative;
  > img {
    object-fit: cover;
    width: 100vw;
    display: flex;
  }
}
`;

export const Body = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 62px;
`;

export const Title = styled.div`
  font-family: SilverSouthSerif;
  font-size: 68px;
  color: #000000;
  letter-spacing: 0;
  text-transform: uppercase;
`;

export const SubHeading = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 700;
  font-size: 28px;
  color: #464855;
  letter-spacing: 0;
  line-height: 48px;
  margin-top: 11px;
`;

export const InputContainer = styled.div`
  height: 62px;
  border: 1px solid rgba(225, 225, 225, 0.74);
  input {
    height: 62px;
    width: calc(370px - 40px);
    color: #A7ABBE;
    font-size: 14px;
    padding: 0 20px;
  }
`;

export const SignInButton = styled.div`
  height: 62px;
  width: 370px;
  font-family: sofia-pro, sans-serif; 
  font-weight: 700;
  font-size: 23px;
  color: #FFFFFF;
  letter-spacing: 0.88px;
  text-transform: uppercase;
  background-color: #FE95A2;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const CreateAccountButton = styled.div`
  height: 62px;
  width: 370px;
  font-family: sofia-pro, sans-serif; 
  font-weight: 700;
  font-size: 23px;
  color: #FFFFFF;
  letter-spacing: 0.88px;
  text-transform: uppercase;
  background-color: #52639B;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const ForgotPasswordLink = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 18px;
  color: #464855;
  letter-spacing: 0;
  text-align: center;
  text-decoration: underline;
  margin-top: 23px;
  margin-left: 60px;
  cursor: pointer;
`;