import React, { useState } from 'react';
import { connect } from 'react-redux';
import { navigate } from '@reach/router';
import { login } from '../../../actions/userActions';
import Input from '../../ThemedInput';
import Button from '../../Button1';
import {
  Container, Body, Title, SubHeading,
  ForgotPasswordLink,
} from './styled';
import background from './background_image.png';

function Login({ login }) {
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  return (
    <Container>
      <div>
        <img src={background} />
        <Body>
          <Title>Hello There,</Title>
          <SubHeading>Your dream wedding gown is waiting for you. </SubHeading>
          <div style={{
            display: 'grid',
            gridGap: 30,
            gridTemplateColumns: '1fr 1fr',
            marginTop: 30,
          }}
          >
            <Input label="Email Address" value={email} update={setEmail} />
            <Input type="password" label="Password" value={password} update={setPassword} />
            <Button style={{ width:370, height: 60 }} color="#124C56" onClick={() => navigate('/create-account')}>Create Account</Button>
            <Button style={{ width:370, height: 60 }} color="#7DBEC8" onClick={() => {
              if (email && password) {
                login({ email, password });
              } else {
                alert('Please enter a valid email an password');
              }
            }}
            >Sign In</Button>
          </div>
          <ForgotPasswordLink onClick={() => navigate('/login/reset')}>I forgot my password</ForgotPasswordLink>
        </Body>
      </div>
    </Container>
  );
}

const mapDispatchToProps = { login };

export default connect(null, mapDispatchToProps)(Login);
