import React from 'react';
import { connect } from 'react-redux';
//components
import Layout from '../components/Layout';
import LoginMobile from '../components/Mobile/Login';
import LoginDesktop from '../components/Desktop/Login';
//actions
import { setMetaTags } from '../actions/homeActions';
//styles
import './style.css';

function Login({ setMetaTags }) {
  return (
    <Layout
      mobile={<LoginMobile />}
      desktop={<LoginDesktop />} />
  );
}

const mapDispatchToProps = { setMetaTags };

export default connect(null, mapDispatchToProps)(Login);